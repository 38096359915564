import { Box, Image } from "@chakra-ui/react";
import { useStoryStyles } from ".";
import { entities2unicode } from "../../shed";

const Message = ({ line, actor, even }) => {
   const { chat: { message: style } } = useStoryStyles();
   const tail = even ? "even" : "odd";

   return (
      <Box __css={style.container}>
         <Box __css={style.line}>{entities2unicode(line)}</Box>
         <Image src={
            even ? "/assets/tail-even.svg" : "/assets/tail-odd.svg"
         } __css={style.tail[tail]} />
         <Box __css={style.actor.container} flexDirection={even ? "row-reverse" : "row"}>
            <Image __css={style.actor.icon} src={actor.icon} />
            <Box __css={style.actor.name}>{entities2unicode(actor.name)}</Box>
         </Box>
      </Box>
   );
};

const Chat = ({ chat }) => {
   const { chat: style } = useStoryStyles();

   // console.log(styles);

   // const style = styles.chat;


   return (
      <Box __css={style.container}>
         {chat.map((message, i) => (
            <Message key={i} {...message} even={(i+1) % 2 === 0} />
         ))}
      </Box>
   );
};

export default Chat;
