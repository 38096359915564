import { Image } from "@chakra-ui/react";
import { useIssueStyles } from ".";

const Ill = ({ image }) => {
   const styles = useIssueStyles();

   return <Image src={image} __css={styles.ill} />;
};

export default Ill;
