export function getDeepKeyValue(obj, keyString) {
   if (!/\./.test(keyString)) return obj[keyString];
   const keys = keyString.split(".");
   let res = obj;
   for (let i = 0; i < keys.length; i++) {
      res = res[keys[i]];
      if (res === undefined) break;
   }
   return res;
}

export const setDeepValue = (key, value) => {
   if (!/[^\.]+\.[^\.]+/.test(key)) return { [key]: value };
   const nested = key.split(".");
   const first = nested.shift();
   return { [first]: setDeepValue(nested.join("."), value) };
};

export function objType(obj) {
   return /^\[object (\w+)]$/
      .exec(Object.prototype.toString.call(obj))[1]
      .toLowerCase();
}

export function entities2unicode(str) {
   if (objType(str) !== "string") {
      // console.log("entities2unicode: type mismatch:", str);
      // throw new Error(str);
      return str;
   }
   let res = str;
   [
      { e: "#9312", u: "\u2460" },
      { e: "#9313", u: "\u2461" },
      { e: "#9314", u: "\u2462" },
      { e: "#9315", u: "\u2463" },
      { e: "#9316", u: "\u2464" },
      { e: "#9317", u: "\u2465" },
      { e: "#9318", u: "\u2466" },
      { e: "#9319", u: "\u2467" },
      { e: "#9320", u: "\u2468" },

      { e: "times", u: "\u00D7" },
      { e: "thinsp", u: "\u2009" },
      { e: "copy", u: "\u00A9" },
      { e: "nbsp", u: "\u00A0" },
      { e: "mdash", u: "\u2014" },
      { e: "ndash", u: "\u2013" },
      { e: "shy", u: "\u00AD" },
      { e: "bdquo", u: "\u201E" },
      { e: "ldquo", u: "\u201C" },
      { e: "laquo", u: "\u00AB" },
      { e: "raquo", u: "\u00BB" },
   ].forEach(entity => {
      res = res.replace(new RegExp(`&${entity.e};`, "g"), entity.u);
   });
   return res;
}

export function lz(n) {
   return n < 10 ? `0${n}` : n.toString();
}

export function round(num, places=0) {
   // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
   const k = Math.pow(10, places);
   return num === 0 ? 0 : Math.round( ( num + Number.EPSILON ) * k ) / k;
}
