import { Box } from "@chakra-ui/react";
import Spinner from ".";

export const SpinnerDemo = () => {
   const sizes = ["24px", "32px", "48px", "64px"];

   return (
      <Box display="flex" flexWrap="wrap">
         {sizes.map((size, i) => (
            <Box key={i} w="240px" h="240px" display="flex" opacity={.4} alignItems="center" justifyContent="center">
               <Spinner color="#fbfaf5" size={size} />
            </Box>
         ))}
      </Box>
   );
};
