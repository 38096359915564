import "./keyframes.css";

const Spinner = ({ color, size="64px", width, height }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 64 64"
         width={width||size}
         height={height||width||size}
      >
         <defs>
            <clipPath id="ClipPath-1">
               {/* <ellipse rx="25" ry="25" fill="#dddddd" transform="translate(8,39) translate(9,-8)"/> */}
               <ellipse
                  rx="31"
                  ry="31"
                  fill="#dddddd"
                  transform="translate(8,39) translate(9,-8)"
               />
            </clipPath>
         </defs>

         <g
            fill={color}
            clipPath="url(#ClipPath-1)"
            transform="translate(32,28) translate(-17,-27)"
         >
            <g
               transform="translate(17,86) scale(1,1.15625) translate(-17,-20.7568)"
               style={{ animation: "2s linear infinite both content_t" }}
            >
               <rect
                  width="34"
                  height="6"
                  transform="translate(19,2.5) translate(-19,-2.5)"
               />
               <rect
                  width="34"
                  height="6"
                  transform="translate(19,15.5) translate(-19,-2.5)"
               />
               <rect
                  width="34"
                  height="6"
                  transform="translate(19,28.5) translate(-19,-2.5)"
               />
            </g>
         </g>

         <ellipse
            fill="none"
            stroke={color}
            strokeDasharray="20 156"
            strokeWidth="6"
            rx="28"
            ry="28"
            transform="rotate(35 -34.746 66.746)"
            style={{
               animation:
                  "2s linear infinite both a0_t,2s linear infinite both a0_da,2s linear infinite both a0_do",
            }}
         />
      </svg>
   );
};

export default Spinner;
