import {
   Box,
   createStylesContext,
   useMultiStyleConfig,
} from "@chakra-ui/react";
import { useStore } from "../../store";
import Chat from "./chat";
import Entities from "./entities";
import Gallery from "./gallery";
import Headlines from "./headlines";
import Title from "./title";

const [StylesProvider, useStyles] = createStylesContext("Story");

export const useStoryStyles = useStyles;

const Story = ({ story }) => {
   const { gallery, headlines, entities, chat } = useStore(({ data }) => {
      const issues = data.issues.filter(issue =>
         issue.stories.includes(story.id)
      );
      // console.log(issues);
      return {
         gallery: {
            images: issues
               .map(issue => ({
                  src: issue.image,
                  title: issue.headline,
                  issueId: issue.id,
                  index: issue.galleryIndex || 0,
               }))
               .slice(0, 4)
               .sort((a, b) => a.index - b.index),
         },
         headlines: issues.map(issue => {
            const outlet =
               data.outlets.find(outlet => outlet.nick === issue.outlet) || {};
            return {
               text: issue.headline,
               outlet,
               issueId: issue.id,
            };
         }),
         entities: data.entities.filter(entity => story.entities.includes(entity.nick)),
         chat: story.chat.map(message => ({
            line: message.line,
            actor: data.entities.find(entity => entity.nick === message.actor) || { name: "unknown" }
         }))
      };
   });

   const styles = useMultiStyleConfig("Story", { storyId: story.id });

   return (
      <StylesProvider value={styles}>
         <Box __css={styles.page}>
            <Title title={story.title} />
            <Gallery {...{ gallery }} />
            <Headlines {...{ headlines }} />
            <Chat {...{ chat }} />
            <Entities {...{ entities }} />
         </Box>
      </StylesProvider>
   );
};

export default Story;
