export const storymaker = {
   parts: ["page", "issues", "narrative", "footer"],
   baseStyle: () => {
      return {
         page: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: "2em",
         },
         issues: {
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
            justifyContent: "center",
            // width: ["100%"],
            px: ["3.125vw", null, null, null, "1vw"],
            rowGap: "16px",
            columnGap: "16px",
            flexGrow: 0,
            // bg: "red",
         },
         narrative: {
            // width: ["100%"],
            // flexGrow: 1,
            // flexBasis: ["100%"],
            minWidth: "300px",
            mx: ["3.125vw", "5vw"],
            my: "2em",
            maxWidth: [null, null, "800px"],
         },
         footer: {
            container: {
               display: "flex",
               alignItems: "center",
               mt: "2em",
            },
            stats: {
               fontSize: ["11px", "12px", "14px"],
               lineHeight: ["18px", "20px", "24px"],
               mr: "4px",
            },
            logo: {
               // width: "42px",
               height: ["36px", "40px", "54px"],
               mx: ["5px", "6px", "10px"],
            },
            typo: {
               fontSize: ["20px", "24px", "34px"],
               // lineHeight: "42px",
               fontFamily: "Bitter",
               fontWeight: "600",
               whiteSpace: "nowrap",
            },
         },
      };
   },
};
