import { issue } from "./issue/style";
import { story } from "./story/style";
import { storymaker } from "./storymaker/style";

const components = {
   Issue: issue,
   Storymaker: storymaker,
   Story: story,
};

export default components;
