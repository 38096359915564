import { Box, Button } from "@chakra-ui/react";

export const setFavicon = src => {
   const node = document.head.querySelector("link[rel='icon']");
   node.setAttribute("href", src);
}

const FaviconTest = () => {

   const favicons = [
      "/favicon02.ico",
      "/favicon03.ico",
      "/favicon04.ico",
   ];

   return (
      <Box display="flex" gap="20px" alignItems="center" p="40px">
         set favicon:
         {
            favicons.map((src, key) => (
               <Button {...{ key }} onClick={()=>setFavicon(src)}>{`Favicon ${key+1}`}</Button>
            ))
         }
      </Box>
   )
};

export default FaviconTest;
