import { useCallback, useState } from "react";
import { round } from "../../shed";
import { AspectRatios, Layouts } from "./controls";
import Gallery from "./gallery";
import { Box } from "@chakra-ui/react";

const grids = {
   "five cols": [9, 6, 4, 2, 1],
   "four cols": [7, 6, 4, 2],
   "three cols": [7, 5, 2],
};

export const aspectRatios = {
   Twitter: [1.91, 1],
   Facebook: [2, 1],
};

const images = {
   "Story 1": [
      "/assets/images/story-01-01.jpg",
      "/assets/images/story-01-02.jpg",
      "/assets/images/story-01-03.jpg",
      "/assets/images/story-01-04.jpg",
      "/assets/images/story-01-05.jpg",
   ],
};

const getSizes = sizes => {
   const sum = sizes.reduce((e, n) => e + n, 0);
   return sizes.map(size => `${round((size / sum) * 100, 2)}%`);
};

export const layouts = Object.fromEntries(
   Object.entries(grids).map(([name, value]) => [name, getSizes(value)])
);

const Gallery2share = () => {
   const [state, set] = useState({
      layout: layouts["five cols"],
      aspectRatio: aspectRatios["Twitter"],
      images: images["Story 1"],
   });

   const setLayout = useCallback(layout => {
      set(state => ({ ...state, layout }));
   }, []);

   const setAspectRatio = useCallback(aspectRatio => {
      set(state => ({ ...state, aspectRatio }));
   }, []);

   return (
      <Box display="flex" flexDir="column" gap="20px" p="20px">
         <Layouts set={setLayout} current={state.layout} />
         <AspectRatios set={setAspectRatio} current={state.aspectRatio} />
         <Gallery {...state} />
      </Box>
   );
};

export default Gallery2share;
