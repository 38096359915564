import { Box } from "@chakra-ui/react";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import ReactMarkdown from "react-markdown";

const Narrative = ({ markdown, style }) => {
   return (
      <Box __css={style}>
         <ReactMarkdown components={ChakraUIRenderer()} skipHtml>
            {markdown}
         </ReactMarkdown>
      </Box>
   );
};

export default Narrative;
