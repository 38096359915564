import {
   Box,
   createStylesContext,
   useMultiStyleConfig,
} from "@chakra-ui/react";
import { useStore } from "../../store";
import Headline from "./headline";
import Ill from "./ill";
import Logotype from "./logotype";
import Text from "./text";

const [StylesProvider, useStyles] = createStylesContext("Issue");

export const useIssueStyles = useStyles;

const Issue = ({ issue }) => {
   const styles = useMultiStyleConfig("Issue", { outlet: issue.outlet });

   // console.log(styles);

   const { image, headline, text } = issue;

   const outlet = useStore(state =>
      state.data.outlets.find(outlet => outlet.nick === issue.outlet)
   );

   return (
      <StylesProvider value={styles}>
         <Box __css={styles.page}>
            <Logotype {...{ outlet }} />
            <Ill {...{ image }} />
            <Headline {...{ headline }} />
            <Text {...{ text }} />
         </Box>
      </StylesProvider>
   );
};

export default Issue;
