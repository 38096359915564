import { Box, Image } from "@chakra-ui/react";
import { useIssueStyles } from ".";

const Logotype = ({ outlet }) => {
   const styles = useIssueStyles();

   return (
      <Box __css={styles.logotype}>
         <Image src={outlet.icon} __css={styles.icon} />
         <Image src={outlet.typo} __css={styles.typo} title={outlet.name} />
      </Box>
   );
};

export default Logotype;
