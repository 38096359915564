import { useCallback } from "react";
import create from "zustand";
import { devtools } from "zustand/middleware";
import { getDeepKeyValue } from "../shed";

export const useStore = create(
   devtools(set => ({
      status: "idle",
      update: props => set(state => ({ ...state, ...props })),
      fetch: async config => {
         set({ status: "loading" });
         const { params = { cache: "no-store" }, ...keys } = config;
         const fetches = Object.entries(keys).map(([key, file]) =>
            fetch(file, params)
               .then(response => {
                  if (/\.json$/.test(file)) return response.json();
                  return response.text();
               })
               .then(response => ({ [key]: response }))
               .catch(error => {
                  console.error(`error in ${file}`, error);
                  set({ status: "error" });
               })
         );
         Promise.all(fetches)
            .then(data => {
               //console.log(data);
               set(data.reduce((state, obj) => ({ ...state, ...obj }), {}));
            })
            .then(() => {
               set({ status: "loaded" });
            });
      },
   }))
);

export const useS = (key, comp) => {
   const callback = useCallback(state => getDeepKeyValue(state, key), [key]);
   const res = useStore(callback, comp);
   return res;
};
