import { Box, Image } from "@chakra-ui/react";
import { useStoryStyles } from ".";
import { entities2unicode } from "../../shed";

const Entity = ({ entity }) => {
   const {
      entities: { entity: style },
   } = useStoryStyles();

   return (
      <Box __css={style.container}>
         <Image src={entity.icon} __css={style.icon} />
         <Box __css={style.label}>{entities2unicode(entity.name)}</Box>
      </Box>
   );
};

const Entities = ({ entities }) => {
   const { entities: style } = useStoryStyles();
   return (
      <Box __css={style.container}>
         {entities.map((entity, i) => (
            <Entity key={i} {...{ entity }} />
         ))}
      </Box>
   );
};

export default Entities;
