import { Box } from "@chakra-ui/react";
import { Fragment } from "react";
import { useIssueStyles } from ".";
import { entities2unicode } from "../../shed";

const split = text => {
   const arr = text.split(/(\[[^\]]+\])/g);
   return arr;
};

const Text = ({ text }) => {
   const str = Array.isArray(text)
      ? text.join(" ").replace(/\s\s+/g, " ")
      : text;

   const arr = split(str);

   // console.log(arr);

   const styles = useIssueStyles();

   // console.log(styles);

   return (
      <Box __css={styles.text}>
         {arr.map((str, i) =>
            /\[[^\]]+\]/.test(str) ? (
               <Box key={i} __css={styles.knownEntity} as="span">
                  {entities2unicode(str.replace(/[\[\]]/g, ""))}
               </Box>
            ) : (
               <Fragment key={i}>{str}</Fragment>
            )
         )}
      </Box>
   );
};

export default Text;
