import "./gallery.css";

const layouts = {
   "five cols": [9, 6, 4, 2, 1],
   "four cols": [7, 6, 4, 2],
   "three cols": [7, 5, 2],
};

// convert relative values to to percentage
const getLayoutWidthsInPercent = layoutName => {
   const sum = layouts[layoutName].reduce((sum, n) => sum + n, 0);
   return layouts[layoutName].map(size => `${((size / sum) * 100).toFixed(2)}%`);
};

const images = {
   "Story 1": [
      "/assets/images/story-01-01.jpg",
      "/assets/images/story-01-02.jpg",
      "/assets/images/story-01-03.jpg",
      "/assets/images/story-01-04.jpg",
      "/assets/images/story-01-05.jpg",
   ],
   "Story 2": [
      "",
      "/assets/images/story-01-02.jpg",
      "",
      "",
      "",
   ],
};

const Gallery = ({ layout, story }) => {
   const widths = getLayoutWidthsInPercent(layout);

   return (
      <div className="container">
         {widths.map((width, i) => (
            <div key={i} className="card" style={{width, zIndex: widths.length-i }}>
               {images[story][i] && <img
                  src={images[story][i]}
                  className="image"
                  alt={`${story}`}
               />}
            </div>
         ))}
      </div>
   );
};

const GalleryDemo = () => {
   return <div className="galleryDemoPage">

      <div>
         <h2>пять, четыре, три колонки</h2>
         {Object.keys(layouts).map((layout, i) => (
            <Gallery key={i} {...{ layout, story: "Story 1" }} />
         ))}
      </div>

      <div>
         <h2>недозагрузились</h2>
         {Object.keys(layouts).map((layout, i) => (
            <Gallery key={i} {...{ layout, story: "Story 2" }} />
         ))}
      </div>

   </div>;
};

export default GalleryDemo;
