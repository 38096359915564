const initialStyle = {
   page: { bg: "#333333" },
};

export const story = {
   parts: ["page", "gallery", "title", "headlines", "headline"],
   baseStyle: ({ storyId, theme }) => {
      const { page } = theme.styles.stories[storyId] || initialStyle;
      return {
         page: {
            display: "flex",
            flexDirection: "column",
            borderRadius: "24px",
            maxWidth: "400px",
            minWidth: "320px",
            padding: "16px",
            pb: "42px",
            gap: "24px",
            ...page,
         },
         gallery: {
            display: "flex",
            borderRadius: "18px",
            overflow: "hidden",
            height: "250px",
            // gap: "2px",
            // bg: "#ffffffee",
            // border: "2px solid #ffffffee",
         },
         title: {
            fontFamily: "Bitter",
            fontSize: "30px",
            lineHeight: "42px",
            fontWeight: 600,
         },
         headlines: {
            container: {
               display: "flex",
               flexDirection: "column",
               gap: "10px",
            },
            headline: {
               container: {
                  display: "flex",
                  gap: "10px",
                  pb: "32px",
                  borderBottom: "3px solid #ffffff33",
               },
               icon: { width: "32px", height: "32px" },
               text: {
                  fontFamily: "Bitter",
                  fontSize: "18px",
                  lineHeight: "24px",
               },
            },
         },
         chat: {
            container: {
               display: "flex",
               flexDirection: "column",
               gap: "16px",
               pb: "32px",
               borderBottom: "3px solid #ffffff33",
            },
            message: {
               container: {
                  display: "flex",
                  flexDirection: "column",
               },
               line: {
                  bg: "#000000",
                  borderRadius: "18px",
                  p: "12px",
                  pb: "18px",
               },
               tail: {
                  even: {
                     flexGrow: 0,
                     height: "16px",
                     alignSelf: "flex-end",
                     mr: "40px",
                  },
                  odd: {
                     flexGrow: 0,
                     height: "16px",
                     alignSelf: "flex-start",
                     ml: "40px",
                  },
               },
               actor: {
                  container: {
                     display: "flex",
                     gap: "12px",
                     alignItems: "center",
                  },
                  icon: { width: "48px", height: "48px" },
                  name: {},
               },
            },
         },
         entities: {
            container: {
               display: "flex",
               flexWrap: "wrap",
               gap: "16px",
               justifyContent: "space-between",
            },
            entity: {
               container: {
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                  width: "100px",
                  alignItems: "center",
               },
               icon: { width: "54px", height: "54px" },
               label: { textAlign: "center" },
            },
         },
      };
   },
};
