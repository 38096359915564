
import tokens from "./tokens.json";

const tokens0 = {
   light: {
      container: {
         icon: {
            square: {
               xs: {
                  outer: {
                     width: "16px",
                     height: "16px",
                     boxShadow: "0 0 0 0.5px white",
                     borderRadius: "2px",
                  },
                  inner: {
                     width: "16px",
                     height: "16px",
                  },
               },
               sm: {
                  outer: {
                     width: "24px",
                     height: "24px",
                  },
                  inner: {
                     width: "22px",
                     height: "22px",
                     borderRadius: "2px",
                     boxShadow: "0 0 0 0.5px white",
                  },
               },
               md: {
                  outer: {
                     width: "32px",
                     height: "32px",
                  },
                  inner: {
                     width: "30px",
                     height: "30px",
                     borderRadius: "3px",
                     boxShadow: "0 0 0 0.5px white",
                  },
               },
               lg: {
                  outer: {
                     width: "48px",
                     height: "48px",
                  },
                  inner: {
                     width: "44px",
                     height: "44px",
                     borderRadius: "4px",
                     boxShadow: "0 0 0 1px white",
                  },
               },
            },
            round: {
               xs: {
                  outer: {
                     width: "16px",
                     height: "16px",
                     borderRadius: "8px",
                     boxShadow: "0 0 0 0.5px white",
                  },
                  inner: {
                     width: "16px",
                     height: "16px",
                     borderRadius: "8px",
                  },
               },
               sm: {
                  outer: {
                     width: "24px",
                     height: "24px",
                     borderRadius: "12px",
                     backgroundColor: "white",
                  },
                  inner: {
                     width: "23px",
                     height: "23px",
                     borderRadius: "12px",
                  },
               },
               md: {
                  outer: {
                     width: "32px",
                     height: "32px",
                     borderRadius: "16px",
                     backgroundColor: "white",
                  },
                  inner: {
                     width: "31px",
                     height: "31px",
                     borderRadius: "16px",
                  },
               },
               lg: {
                  outer: {
                     width: "48px",
                     height: "48px",
                     borderRadius: "24px",
                     backgroundColor: "white",
                  },
                  inner: {
                     width: "46px",
                     height: "46px",
                     borderRadius: "23px",
                  },
               },
            },
         },
      },
   },
};

const styles = {
   outer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
   },
   inner: {
      overflow: "hidden",
   },
};

const Icon = ({ domain, country, edition, shape = "square", size = "sm" }) => {
   const src = `/assets/sources/${shape}/${domain}_${country}_${edition}.jpg`;

   const token = tokens.light.container.icon[shape][size];
   const outer = { ...styles.outer, ...token.outer };
   const inner = { ...styles.inner, ...token.inner };
   const { width, height } = inner;

   return (
      <div style={outer}>
         <div style={inner}>
            <img {...{ src }} style={{ width, height }} alt={domain} />
         </div>
      </div>
   );
};

const IconDemo = ({ source }) => {
   const sizes = ["xs", "sm", "md", "lg"];
   const bgs = ["white", "#ccc", "#999", "#666", "black"];

   return (
      <div style={{ display: "flex", flexDirection: "column" }}>
         {bgs.map((backgroundColor, i) => (
            <div key={i} style={{ display: "flex" }}>
               {sizes.map((size, i) => (
                  <div
                     key={i}
                     style={{
                        backgroundColor,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "0 24px",
                        height: "64px",
                     }}
                  >
                     <Icon {...source} {...{ size }} />
                  </div>
               ))}
            </div>
         ))}
      </div>
   );
};

const IconDemoPage = () => {
   const selectedSources = [
      { domain: "aljazeera.com", country: "qa", edition: "en" },
      { domain: "dw.com", country: "de", edition: "ru" },
      { domain: "fn-volga.ru", country: "ru", edition: "ru", shape: "round" },
      { domain: "yuga.ru", country: "ru", edition: "ru", shape: "round" },
   ];

   return (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
         {selectedSources.map((source, i) => (
            <IconDemo key={i} {...{ source }} />
         ))}
      </div>
   );
};

export default IconDemoPage;
