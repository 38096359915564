import { ColorModeScript } from "@chakra-ui/react";
import React, { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
// import theme from "./theme";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

// console.log(theme.config.initialColorMode);

const currmode = localStorage.getItem("chakra-ui-color-mode");
// console.log(currmode);
if (currmode === "light") localStorage.setItem("chakra-ui-color-mode", "dark");

root.render(
   <>
      <ColorModeScript initialColorMode="dark" />
      <BrowserRouter>
         <App />
      </BrowserRouter>
   </>
);

/* root.render(
   <StrictMode>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
      <App />
      </BrowserRouter>
   </StrictMode>
); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
