import { Box, Image } from "@chakra-ui/react";
import { Fragment } from "react";
import { useStoryStyles } from ".";

const VR = () => (
   <svg viewBox="0 0 2 250" width="2" height="100%" style={{ flexShrink: 0 }}>
      <rect width="2" height="100%" fill="#ffffffdd" />
   </svg>
);

const Gallery = ({ gallery }) => {
   const styles = useStoryStyles();

   const widths = ["55%", "27%", "13%", "5%"];

   return (
      <Box __css={styles.gallery}>
         {gallery.images.map((image, i) => (
            <Fragment key={i}>
               <Image
                  src={image.src}
                  title={image.title}
                  width={widths[i]}
                  fit="cover"
               />
               {i < 3 && <VR />}
            </Fragment>
         ))}
      </Box>
   );
};

export default Gallery;
