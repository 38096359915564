import { Box, Flex, Button } from "@chakra-ui/react";
import { isEqual } from "lodash";
import { aspectRatios, layouts } from ".";

export const AspectRatios = ({ set, current }) => {
   return (
      <Flex gap="10px">
         {Object.entries(aspectRatios).map(([name, value], key) => (
            <Button
               color={isEqual(current, value)?"yellow":null}
               onClick={() => {
                  if (!isEqual(current, value)) set(value);
               }}
               {...{ key }}
            >
               {`${name} ${value.join(":")}`}
            </Button>
         ))}
      </Flex>
   );
};

export const Layouts = ({ set, current }) => {
   // useEffect(() => { console.log("Layouts after render"); }, []);
   return (
      <Flex gap="10px">
         {Object.entries(layouts).map(([name, value], key) => (
            <Button
               color={isEqual(current, value)?"yellow":null}
               onClick={() => {
                  if(!isEqual(current, value)) set(value);
               }}
               {...{ key }}
            >
               {name}
            </Button>
         ))}
      </Flex>
   );
};
