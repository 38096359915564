import { Box } from "@chakra-ui/react";
import { useStoryStyles } from ".";
import { entities2unicode } from "../../shed";

const Title = ({ title }) => {
   const styles = useStoryStyles();

   // console.log("title styles:", styles);

   return <Box __css={styles.title}>{entities2unicode(title)}</Box>;
};

export default Title;
