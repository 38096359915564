const initialStyle = {
   text: {},
   headline: {},
   page: {},
};

export const issue = {
   parts: [
      "page",
      "logotype",
      "icon",
      "typo",
      "headline",
      "text",
      "knownEntity",
   ],
   baseStyle: ({ outlet, theme, ...props }) => {
      // console.log(props);
      const { text, page, headline } =
         theme.styles.outlets[outlet] || initialStyle;
      return {
         logotype: {
            display: "flex",
            gap: "10px",
            height: "42px",
            // width: "268px",
            // justifyContent: "flex-start",
         },
         icon: { width: "42px", height: "42px" },
         typo: { width: "216px", height: "42px" },
         page: {
            borderRadius: "24px",
            display: "flex",
            flexDir: "column",
            alignItems: "flex-start",
            gap: "16px",
            p: "16px",
            pb: "32px",
            width: [
               "100%",
               null,
               "calc(50% - 16px)",
               null,
               "calc(25% - 16px * 3)",
            ],
            minWidth: "300px",
            maxWidth: "400px",
            ...page,
         },
         ill: {
            objectFit: "cover",
            height: "200px",
            width: "100%",
            maxWidth: "400px",
         },
         headline: {
            ...headline,
         },
         text: {
            fontFamily: "Flow Block",
            fontSize: "24px",
            lineHeight: "22px",
         },
         knownEntity: {
            // display: "inline-block",
            position: "relative",
            lineHeight: "1px",
            ...text,
         },
      };
   },
};
