import { Box } from "@chakra-ui/react";
import { useIssueStyles } from ".";
import { entities2unicode } from "../../shed";

const Headline = ({ headline }) => {
   const styles = useIssueStyles();

   return <Box __css={styles.headline}>{entities2unicode(headline)}</Box>;
};

export default Headline;
