import React, { useEffect, useState } from "react";
import {
   Button,
   ChakraProvider,
   extendTheme,
   Flex,
   useColorMode,
   useTheme,
} from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
// import theme from "./theme";
import { useS, useStore } from "./store";
import Storymaker from "./components/storymaker";
import components from "./components";
import FaviconTest from "./favicon";
import Gallery2share from "./components/gallery2share";
import Gallery from "./components/gallery";
import GalleryDemo from "./components/gallery";
import IconDemoPage from "./components/icon";
import { SpinnerDemo } from "./components/spinner/demo";

/* import "@fontsource/flow-block/400.css";
import "@fontsource/manrope/800.css";
import "@fontsource/manrope/500.css";
import "@fontsource/bitter/500.css";
import "@fontsource/bitter/600.css";
import "@fontsource/bitter/700.css";
import "@fontsource/bitter/800.css";
import "@fontsource/bitter/900.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css"; */

// console.log(theme);

const Dummy = () => {
   // const { toggleColorMode } = useColorMode();

   const theme = useTheme();

   useEffect(() => {
      console.log("Dummy after render");
      console.log("theme", theme);
   }, [theme]);

   return (
      <Flex
         textAlign="center"
         fontSize="xl"
         minH="100vh"
         alignItems="center"
         justifyContent="center"
      >
         {/* <Button onClick={toggleColorMode}>toggle</Button> */}
         Dummy
      </Flex>
   );
};

function App() {
   const status = useS("status");
   const themeExtension = useS("data.theme");
   const fetchData = useStore(state => state.fetch);
   const [extendedTheme, setExtendedTheme] = useState();

   useEffect(() => {
      // console.log("Status:", status);
      if (status === "idle") {
         fetchData({
            data: "/assets/json/config.json",
            text1: "/assets/text/part1.txt",
            text2: "/assets/text/part2.txt",
            text3: "/assets/text/part3.txt",
         });
      }
   }, [status, fetchData]);

   useEffect(() => {
      if (status !== "loaded") return;
      // console.log(themeExtension);

      setExtendedTheme(
         extendTheme({
            ...themeExtension,
            components,
         })
      );
   }, [status, themeExtension]);

   useEffect(() => {
      if (!extendedTheme) return;
      const browserTheme = document.querySelector("meta[name='theme-color']");
      const bg = extendedTheme.colors.trueStory.paper;
      if (!browserTheme) return;
      if (browserTheme.getAttribute("content") === bg) return;
      // console.log(browserTheme);
      browserTheme.setAttribute("content", bg);
   }, [extendedTheme]);

   return extendedTheme ? (
      <ChakraProvider theme={extendedTheme}>
         <Routes>
            <Route
               path="/"
               element={
                  <Storymaker storyId="723ab651-bc3f-4d44-8aa2-9c4f0aeed1bd" />
               }
            />
            <Route
               path="/favicon"
               element={
                  <FaviconTest />
               }
            />
            <Route
               path="/gallery2share"
               element={
                  <Gallery2share />
               }
            />
            <Route
               path="/gallery"
               element={
                  <GalleryDemo />
               }
            />
            <Route
               path="/icon"
               element={
                  <IconDemoPage />
               }
            />
            <Route
               path="/spinner"
               element={
                  <SpinnerDemo />
               }
            />
         </Routes>
      </ChakraProvider>
   ) : (
      <div>...</div>
   );
}

export default App;
