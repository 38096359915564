import {
   Box,
   // Button,
   createStylesContext,
   Divider,
   Image,
   Text,
   useBreakpoint,
   useBreakpointValue,
   // useColorMode,
   useMultiStyleConfig,
   // useTheme,
} from "@chakra-ui/react";
import Issue from "../issue";
import { useS, useStore } from "../../store";
import Story from "../story";
import Narrative from "./narrative";
import { lz } from "../../shed";

const [StylesProvider, useStyles] = createStylesContext("Issue");
export const useStorymakerStyles = useStyles;

const Issues = ({ issues }) => {
   const styles = useStorymakerStyles();
   return (
      <Box __css={styles.issues} name="issues">
         {issues.map((issue, i) => (
            <Issue key={i} {...{ issue }} />
         ))}
      </Box>
   );
};

const Footer = () => {
   const { footer: style } = useStorymakerStyles();
   const d = new Date();
   const sokr = useBreakpointValue([
      {
         issue: "вып.",
         version: "вер.",
         year: d.getFullYear().toString().slice(-2),
      },
      { issue: "выпуск", version: "версия", year: d.getFullYear() },
   ]);
   return (
      <Box __css={style.container}>
         <Box __css={style.stats}>
            <Box whiteSpace="nowrap">
               {sokr.issue}&nbsp;RU&nbsp;
               {`${d.getDate()}.${lz(d.getMonth() + 1)}.${sokr.year}`}
               &nbsp;&nbsp;{sokr.version}&nbsp;0.1.0
            </Box>
            <Box whiteSpace="nowrap">4 источника &middot; 1 сюжет</Box>
         </Box>
         <Image __css={style.logo} src="/assets/logo-footer.svg" />
         <Box __css={style.typo}>the True Story</Box>
      </Box>
   );
};

const Storymaker = ({ storyId }) => {
   // const { toggleColorMode } = useColorMode();

   const story = useStore(state => {
      // console.log(state);
      return state.data.stories.find(story => story.id === storyId);
   });
   const issues = useStore(state =>
      state.data.issues.filter(issue => issue.stories.includes(storyId))
   );

   const styles = useMultiStyleConfig("Storymaker");

   const bp = useBreakpoint();

   const text1 = useS("text1");
   const text2 = useS("text2");
   const text3 = useS("text3");

   return (
      <StylesProvider value={styles}>
         <Box __css={styles.page} name="page">
            {/* <div>{bp}</div> */}
            <Narrative markdown={text1} style={styles.narrative} />
            <Issues {...{ issues }} />
            <Box width="100%" px="16px" py="16px">
               <Divider borderBottom="3px solid #ffffff55" />
            </Box>
            <Narrative markdown={text2} style={styles.narrative} />
            <Story {...{ story }} />
            <Narrative markdown={text3} style={styles.narrative} />
            <Box width="100%" px="16px" py="16px">
               <Divider borderBottom="3px solid #ffffff55" />
            </Box>
            <Footer />
            {/* <div>{bp}</div> */}
         </Box>
      </StylesProvider>
   );
};

export default Storymaker;
