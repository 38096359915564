import { Box, Image } from "@chakra-ui/react";
import { useEffect } from "react";

const defaultWidth = 1000;

const zoom = .5;

const Piece = ({ src, width, height, style }) => {
   return (
      <Image {...{src, width, height}} fit="cover" boxSizing="border-box" backgroundClip="padding-box" __css={style} />
   )
};

const style = {
   // borderRight: "8px solid rgba(255, 255, 255, 0.6)",
   boxShadow: "0 0 0 1px rgba(255, 255, 255, 0.4), 0 0 8px 8px rgba(0, 0, 0, 0.4)"
};

const Gallery = ({ layout, aspectRatio, images }) => {

   const [x, y] = aspectRatio;

   const height = defaultWidth*(y/x)*zoom;

   useEffect(() => {
      console.log(`aspectRatio: ${aspectRatio.join(":")}`);
   }, [aspectRatio]);

   useEffect(() => {
      console.log(`layout: ${layout.join(":")}`);
   }, [layout]);

   return (
      <Box display="flex" overflow="hidden" width={`${defaultWidth*zoom}px`} height={`${height}px`} bg="black">
         {layout.map((w, i) => (
            <Piece key={i} src={images[i]} width={w} height={`${height}px`} style={i<layout.length-1 ? {...style, zIndex: layout.length-i } : {}} />
         ))}
      </Box>
   )
};

export default Gallery;
