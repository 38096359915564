import { Box, Image } from "@chakra-ui/react";
import { useStoryStyles } from ".";
import { entities2unicode } from "../../shed";

const Headline = ({ headline }) => {
   const {
      headlines: { headline: style },
   } = useStoryStyles();

   return (
      <Box __css={style.container}>
         <Image
            src={headline.outlet.icon}
            title={headline.outlet.name}
            __css={style.icon}
         />
         <Box __css={style.text}>{entities2unicode(headline.text)}</Box>
      </Box>
   );
};

const Headlines = ({ headlines }) => {
   const { headlines: style } = useStoryStyles();
   return (
      <Box __css={style.container}>
         {headlines.map((headline, i) => (
            <Headline key={i} {...{ headline }} />
         ))}
      </Box>
   );
};

export default Headlines;
